import React from "react"
import styled from "styled-components"

const Root = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  @media (max-width: 1100px) {
    margin: 1rem 0;
    }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  padding: 0 3rem;

    @media (max-width: 1100px) {
        flex-direction: column;
        padding: 0 1rem;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;

    @media (min-width: 1100px) {
        flex: 50%;
    }
`;

const ContentContainer = styled.div`
    @media (min-width: 1100px) {
        flex: 50%;
    }
`

const Img = styled.img`
    width: 100%;
    height: 100%;
    max-width: 400px;
`;

const Heading = styled.h2`
    font-size: 2.5rem;
    color: #18232e;
    margin: 2rem 0 0;
`;

const ImageGroup = ({ image, heading, reverse, children }) => {
    return (
        <Root>
            <Container reverse={reverse}>
                <ContentContainer>
                    <Heading>{heading}</Heading>
                    <div>
                        {children}
                    </div>
                </ContentContainer>
                <ImageContainer>
                    <Img alt="Hero" src={image} />
                </ImageContainer>
            </Container>
        </Root>
    )
}

export default ImageGroup
