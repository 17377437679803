import React from "react"
import ImageGroup from "../ui/imageGroup"

const AboutUsSection = ({ reverse }) => {
    return (
        <ImageGroup reverse={reverse} image="../images/team.svg" heading="The Team">
            <p>
                The Beyond Attendance team is a committed group of technical minded individuals with many years’ experience in this industry. Our drive and passion for technology allows us to stay at the forefront of our field and consistently offer the best end user experience possible.
            </p>
            <p>
                Our base of operations are located in South Africa with servers in Northern Europe. We make extensive use of Microsoft technologies and services which enables us to ensure data integrity and instant scalability.
            </p>
            <p>
                We take great pride in delivering exceptional, personal after sales support knowing full well how vital constant up-time is when dealing with payroll related activities. We are dedicated to serving you with the best possible product offering to fit you and your organisation’s needs, so get in touch with us and let’s make your Time and Attendance work for you!
            </p>
        </ImageGroup>
    )
}

export default AboutUsSection;
