import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"
import AboutUsSection from "../components/sections/aboutUsSection"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <HeroText
      statement="About Us"
      caption="We pride ourselves on our technical ability and our exceptional after sales support."
    ></HeroText>
    <AboutUsSection />
  </Layout>
)

export default AboutUsPage
